import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Layout } from '@components';
import { Gallery, Item } from 'react-photoswipe-gallery';
import 'photoswipe/dist/photoswipe.css';
import { PreparedPhotoSwipeOptions } from 'photoswipe';

const Album: React.FC<any> = ({ data, pageContext: { locale, slug } }) => {
    const options = {
        bgOpacity: 0.4,
        showHideAnimationType: 'fade',
        indexIndicatorSep: ' / '
    };
    const messages = {
        closeTitle: 'Zavřít',
        zoomTitle: 'Přiblížit',
        arrowPrevTitle: 'Předchozí',
        arrowNextTitle: 'Následující',
        mainClass: 'pswp--glassmorph-bg',
        errorMsg: 'Tento obrázek se nepodařilo načíst'
    };
    return (
        <Layout lang={locale} path={`/gallery/${slug}`}>
            <h1 className="font-bold text-center mt-28 ">{data.strapiGallery.Title}</h1>
            <Gallery
                options={
                    (locale == 'en'
                        ? options
                        : { ...options, ...messages }) as PreparedPhotoSwipeOptions
                }>
                <div className="grid w-full grid-cols-1 gap-4 my-20 pswp-gallery justify-items-center md:grid-cols-3">
                    {data.strapiGallery.Images.map((node, index) => {
                        if (!node.localFile) {
                            console.log(node);
                        }
                        return (
                            <Item
                                key={index}
                                original={
                                    node.localFile?.childImageSharp.gatsbyImageData.images.fallback
                                        .src
                                }
                                width={node.localFile?.childImageSharp.gatsbyImageData.width}
                                height={node.localFile?.childImageSharp.gatsbyImageData.height}>
                                {({ ref, open }) => (
                                    <span ref={ref} onClick={open}>
                                        <GatsbyImage
                                            imgClassName="hover:scale-110 !transition-all scale-100"
                                            className="object-center w-32 h-auto cursor-pointer item max-h-40 lg:w-60"
                                            image={node.localFile?.childImageSharp.gatsbyImageData}
                                            alt={node.localfile?.name || data.strapiGallery.Title}
                                            objectFit="cover"
                                            objectPosition={'center'}
                                        />
                                    </span>
                                )}
                            </Item>
                        );
                    })}
                </div>
            </Gallery>
        </Layout>
    );
};

export default Album;

export const query = graphql`
    query photos($slug: String!, $locale: String!) {
        strapiGallery(slug: { eq: $slug }, locale: { eq: $locale }) {
            Title
            Images {
                localFile {
                    name
                    childImageSharp {
                        gatsbyImageData(placeholder: BLURRED, blurredOptions: { width: 150 })
                    }
                }
                url
            }
        }
    }
`;
